
import { defineComponent } from "vue";

export default defineComponent({
  name: "Chips",
  props: {
    chips: Object as () => string[] | null,
    classList: String as () => string,
  },
});
