import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "chips"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.chips)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chips, (chip, index) => {
          return (_openBlock(), _createElementBlock("span", {
            key: index,
            class: "tag is-medium"
          }, _toDisplayString(chip), 1))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}