
import { defineComponent } from "vue";
import { GenericContent, paginatorService } from "@/services/paginator.service";
import PageHeadline from "@/common/PageHeadline.vue";
import Chips from "@/common/Chips.vue";
import { contentsService, StaticContent } from "@/services/contents.service";
import OpenPositionApply from "@/modules/join-us/OpenPositionApply.vue";

export default defineComponent({
  name: "OpenPosition",
  components: { OpenPositionApply, Chips, PageHeadline },
  data() {
    return {
      slug: "" as string,
      page: undefined as StaticContent | undefined,
      item: undefined as GenericContent | undefined,
    };
  },
  mounted() {
    if (this.$route.params.slug) {
      this.slug = this.$route.params.slug as string;
      this.loadContent(this.$route.params.slug as string);
    }
  },
  methods: {
    loadContent(slug: string) {
      contentsService.findBySlug("page-join-us").then((page) => {
        this.page = page;
      });
      paginatorService.findBySlug("open-positions", slug).then((item) => {
        if (!item) {
          this.$router.push("/join-us/");
        } else {
          this.item = item;
        }
      });
    },
  },
  watch: {
    slug(slug) {
      if (slug) {
        this.loadContent(slug);
      } else {
        this.item = undefined;
      }
    },
  },
});
